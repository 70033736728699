<template>
    <div class="faq-page">
        <page-header title="Documentation and materials" />
        <div class="grid faq-list">
            <div v-for="item in list" :key="item.title" class="faq__item flex border border-brand-color border-radius">
                <img :src="`/faq/${item.img}`" :alt="`${item.title} pdf document`" class="w-2/5 mr-4">
                <div class="faq__content flex flex__column justify-between">
                    <div>
                        <p><b>{{ item.platform }}</b> platform:</p>
                        <p>{{ item.title }}</p>
                    </div>
                    
                    <p>{{ item.date }}</p>

                    <p v-if="Object.keys(item.documents).length">
                        Download PDF:
                        <span
                            v-for="(key, index) in Object.keys(item.documents)"
                            :key="key"
                        >
                            <span v-if="index > 0">/</span>
                            <a
                                :href="`/faq/${item.documents[key]}`"
                                target="_blank"
                                class="capitalize"
                            >
                                {{ key }}
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FaqPage',
    data() {
        return {
            list: [
                {
                    img: 'image2.png',
                    platform: 'ONE',
                    title: 'User introduction presentation',
                    date: 'As of March 2023',
                    documents: {
                        en: 'ONE-user-intro-en.pdf',
                        de: 'ONE-user-intro-de.pdf',
                    },
                },
                {
                    img: 'image1.png',
                    platform: 'ONE',
                    title: 'User manual',
                    date: 'As of March 2023',
                    documents: {
                        en: 'ONE-manual-en.pdf',
                        de: 'ONE-manual-de.pdf',
                    },
                },
            ],
        };
    },
}
</script>
<style lang="scss" scoped>
.faq {
    &-list {
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        img {
            object-fit: cover;
            aspect-ratio: 1 / 1;
        }
    }
    &__item {
        position: relative;
        overflow: hidden;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: var(--brand-color);
            opacity: .1;
        }
    }
    &__content {
        padding: 12px 12px 12px 0;
        font-size: 18px;
        z-index: 1;
    }
}
</style>